@import '../../variables';




.login-container {
    display: block;
    text-align: center;

}


.login-modal-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-modal {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}
