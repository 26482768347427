@import './variables';


$loader-color: #7fc9fa;
$loader-circle: #f3f3f3;


* {
  box-sizing: border-box

}


.App {
  text-align: center;
  width: 100%;
  height: 100%;
}


.slow-load {
  opacity: 1;
  animation: slow-load-anim;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes slow-load-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


h1 {
  font-size: 70px;
  color: white;
  text-shadow: 1px 1px white, 
                2px 2px $primary-btn, 
                3px 3px $primary-btn,
                4px 4px $primary-btn,
                0px 0px 40px $primary-btn;
                
              //  0px 0px 15px rgb(233, 100, 233);
 
  transition: 500ms;
                
           
}

.loader-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}



.hide {
  display: none;
}




textarea:disabled, input:disabled {
  opacity: 0.5 !important;
}


.loader.tiny {
  border: 2px solid $loader-circle; /* Light grey */
  border-top: 2px solid $loader-color; /* Blue */
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.loader.small {
  border: 4px solid $loader-circle; /* Light grey */
  border-top: 4px solid $loader-color; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.loader {
  border: 16px solid $loader-circle; /* Light grey */
  border-top: 16px solid $loader-color; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 0.9s linear infinite;
}




.loader.med {
  width: 60px;
  height: 60px;
  border: 8px solid $loader-circle; /* Light grey */
  border-top: 8px solid $loader-color; /* Blue */
}

.loader.small { width: 30px; height: 30px;}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.row, .btn-row{
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}

h1:hover {
  text-shadow: 1px 1px white, 
  2px 2px $primary-btn, 
  3px 3px $primary-btn,
  4px 4px $primary-btn, 
  5px 5px $primary-btn, 
  6px 6px $primary-btn,
  7px 7px $primary-btn, 
  8px 8px $primary-btn, 
  9px 9px $primary-btn,
  0px 0px 100px $primary-btn;
  //0px 0px 100px rgb(255, 151, 255);
  color: white;
  
                
}






.input-group.valid {
  input, textarea {
    background-color: rgb(193, 236, 193) !important;
  }
}

.input-group.invalid {

  input, textarea {
    background-color: rgb(255, 208, 208) !important;
  }
}


.form-group > .checkbox-group,
.form-group > .input-group {
  width: 100%;
  justify-content: left;

  label {
    width:20%;
    text-align: right;
  }
  

}



.checkbox-group > label,
.input-group > label {
  font-size: 14px;
  text-align: right;
}



.checkbox-wrapper {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.checkbox-wrapper > input {
  outline: none;
  border: none ;
  height: 20px;
  min-width: 0px;
  box-shadow: none;
}

.checkbox-group,
.input-group {
  display: flex;
  margin: 5px;
  justify-content: center;
  align-items: center;
}


.input-group > input, .input-group > textarea, .checkbox-group > input {
  margin: 5px;
}



// If the something is required adds the astrix.
.required {
  &::after {
    content:' *';
    color: red;
    font-size: 12px;
  }
} 

@mixin input-block() {
  border-radius: 5px;
  width: 250px;
  //min-width: 50px;
  text-indent: 10px;
  border: none;
  background-color: rgb(230,230,230);
  outline: none;
  transition: box-shadow 500ms, background-color ease-in-out 500ms, border-radius 500ms, opacity 500ms;
}


.input-group > input, textarea {
  @include input-block();
  height: 40px;
}

textarea {
  @include input-block();
  height: 80px !important;
  padding: 5px;
  width: 250px;
  resize: none;
}




.input-group > input:focus, textarea:focus, textarea:not(:placeholder-shown), textarea:hover, .input-group > input:not(:placeholder-shown), .input-group > input:hover {
  box-shadow: 0px 0px 0px 1px rgb(179, 177, 177) !important;
  border-radius: 15px;
}

.input-group > input:focus, .input-group > input:not(:placeholder-shown), textarea:focus, textarea:not(:placeholder-shown) {
  background-color: rgb(255, 255, 255) !important;
}


button.notify {
  position: relative;
}

button.selected {
  background-color: $primary-selected;
}

button:disabled {
  opacity: 0.4;
}

button:disabled:hover {
  background-color: $primary-btn;
  box-shadow: none;
}



h4 {
  font-size: 12px;
  color: rgb(0, 0, 0);           
}


h2 {
  font-size: 40px;
}


header {
  display: block;
  margin-bottom: 30px;
}



.body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

}


button {

  background-color: $primary-btn;
  border-radius: 5px;
  border: none;
  color: white;
  outline: none;
  margin: 5px;
  transition: 0.6s;
  height: 30px;
  width: 110px;

}


button:hover {
  background-color: $accent-btn;
  //box-shadow: 0px 0px 12px 2px $accent-btn;
}



.block {
  display: block;
}



.modal-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  width: 100%;

//  justify-content: center;
  //align-items: center;
}


.center {
  justify-content: center;
  align-items: center;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

.full {
  width: 100%;
}

.three-quarter {
  width: 75%;
}

.half {
  width: 50%;
}

.third {
  width: 33.3%;
}

.two-third {
  width: 66.6%;
}

.quarter {
  width: 25%;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}






tr {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

tr.detail-row {
  margin: none;
}


tr.detail-row > td:first-of-type {
  border-right: 1px solid rgba(77, 77, 77, 0.2);
  border-top: 1px solid rgba(77, 77, 77, 0.2);
  text-align: left;
}

tr.detail-row > td:last-of-type {
  text-align: right;
}

td.detail-li {
  margin:0;
  padding: 5px !important;
}

.toolbar {
  border: 1px solid rgb(110, 110, 110);
  background-color: white;
  margin-bottom: 20px;
}




$fulfilled-text: rgb(84, 148, 69);//rgb(71,112,45);
$fulfilled-background: rgb(160, 236, 160);
$unfulfilled-text: rgb(196, 193, 43);
$unfulfilled-background: rgb(247, 239, 128);
$cancelled-text: rgb(189, 59, 59);
$cancelled-background: rgb(241, 154, 154);


.active,
.fulfilled, 
.settling{
    background-color: $fulfilled-background;
    color: $fulfilled-text;
}


.partially-refunded,
.onhold,
.unfulfilled {
    background-color: rgb(247, 239, 128);
    color: rgb(112, 111, 45);
}


.refunded,
.cancelled {
    background-color: rgb(241, 154, 154);
    color: rgb(83, 2, 2);
}

.tag {
    font-size:12px;
    border: none;
    border-radius: 10px;
    padding: 6px;
    padding-right:7px;
} 

.tag-bullet {
    height: 7px;
    width: 7px;
    margin-right: 3px;
    border-radius: 50%;
    display: inline-block;
}

.active .tag-bullet,
.fulfilled .tag-bullet,
.settling .tag-bullet {
    background-color:$fulfilled-text;
}


.partially-refunded .tag-bullet,
.onhold .tag-bullet,
.unfulfilled .tag-bullet {
    background-color:none;
    border: 1px solid $unfulfilled-text;
}


.refunded .tag-bullet,
.cancelled .tag-bullet {
    background-color: $cancelled-text;
}


/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

$modal-radius: 15px;

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: $modal-radius;
}


.modal-content.small {
  width: 600px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}


/* The Close Button */
.close-modal {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}


.close-modal:hover,
.close-modal:focus {
  color:gray;
  text-decoration: none;
  cursor: pointer;
}




.modal-header {
  padding: 2px 16px;
  background-color: white;
  color: black;
  border: none;
  border-top-left-radius: $modal-radius;
  border-top-right-radius: $modal-radius;
  border-bottom: 1px solid rgba(20,20,20, 0.2);
  
}

.modal-body {

  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: white;
  color: black;
  border: none;
  border-bottom-right-radius: $modal-radius;
  border-bottom-left-radius: $modal-radius;
  border-top: 1px solid rgba(20,20,20, 0.2);
}



span.checkmark {
  content:'\2713';
  color:rgb(84, 148, 69);
  font-size: 100px;
  opacity: 1;
  animation-iteration-count: 1;
  animation: checkmark-intro-anim 2s;
}

span.checkmark.small {
  color:rgb(0, 255, 21);
  font-size: 30px;

}

span.checkmark.tiny {
  font-size: 15px;
  color:rgb(0, 255, 21);
}



button.error {
  background-color: rgb(196, 70, 70);
}

button.error:hover {
  background-color: red;
}

[fadeIn] {

    animation: fadeInAnim 1.5s;
    animation-iteration-count: 1;
}


@keyframes fadeInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes checkmark-intro-anim {
  0% {
  //  font-size: 0px;
    opacity: 0;
  }

  100% {
  //  font-size: 100px;
    opacity: 1;
  }
}


.error-message { 
  color: $error-red;
  max-width: 250px;
  min-height: 36px !important;
}



button > span.btn-notify {
  background-color: rgb(197, 0, 0);
  color: white;
  padding: 5px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 5px;

  border: none;
  position: absolute;
  top: -5px;
  right: -5px;
}



button.standard-btn {
  height: 40px !important
}

@media only screen and (max-width: 568px) {
  .renewall {
    display: none !important;
  }
}



.detail-container {
  display: flex;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  overflow-x: scroll;
}





.detail-column {
  padding: 10px;
  text-align: left;
  margin: 0 20px;
}

.detail-column-header {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}

.detail-column-item {
  text-align: left;
  font-size: 14px;
}
