$background-color: rgb(221, 221, 221);
$primary-btn: rgb(93, 122, 150);
$primary-secondary-btn: rgb(56, 114, 168);

$accent-btn: rgb(37, 81, 122);
$accent-secondary-btn: #3225a5;
$primary-text: white;
$primary-secondary-text:#2339b4;

$primary-selected: rgb(37, 81, 122);


$error-red:rgb(255, 6, 6);